/**
 * 预览管理端
 */

import { nextTick, onMounted, onUnmounted, Ref, ref, unref, watch } from "vue";

const bgImage = 'https://computed-cdn.langtaoshuzi.com/template/default/bgImage.jpg';
const headerBtnBg = 'https://computed-cdn.langtaoshuzi.com/template/default/headerBtnBg.png';
const leftIconBtn = 'https://computed-cdn.langtaoshuzi.com/template/default/leftIconBtn.png';
const headerBg = 'https://computed-cdn.langtaoshuzi.com/template/default/headerBg.png';
const rightCloudBtn = 'https://computed-cdn.langtaoshuzi.com/template/default/rightCloudBtn.png';
const rightSetBtn = 'https://computed-cdn.langtaoshuzi.com/template/default/rightSetBtn.png';
const keyboardFooterBg = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardFooterBg.png';
const keyboardBg = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardBg.png';
const keyboardHeaderBg = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardHeaderBg.png';
const boardNormalC = 'https://computed-cdn.langtaoshuzi.com/template/default/boardNormalC.png';
const boardNormalCDown = 'https://computed-cdn.langtaoshuzi.com/template/default/boardNormalCDown.png';
const keyboardNum0 = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum0.png';
const keyboardNum0Down = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum0Down.png';
const keyboardNumEq = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNumEq.png';
const keyboardNumEqDown = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNumEqDown.png';
const keyboardNumChu = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNumChu.png';
const keyboardNumChuDown = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNumChuDown.png';
const keyboardCheng = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardCheng.png';
const keyboardChengDown = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardChengDown.png';
const keyboardTui = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardTui.png';
const keyboardTuiDown = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardTuiDown.png';
const keyboardNum1 = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum1.png';
const keyboardNum1Down = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum1Down.png';
const keyboardNum2 = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum2.png';
const keyboardNum2Down = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum2Down.png';
const keyboardNum3 = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum3.png';
const keyboardNum3Down = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum3Down.png';
const keyboardSub = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardSub.png';
const keyboardSubDown = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardSubDown.png';

const keyboardNum4 = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum4.png';
const keyboardNum4Down = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum4Down.png';
const keyboardNum5 = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum5.png';
const keyboardNum5Down = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum5Down.png';
const keyboardNum6 = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum6.png';
const keyboardNum6Down = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum6Down.png';
const keyboardAdd = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardAdd.png';
const keyboardAddDown = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardAddDown.png';

const keyboardNum7 = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum7.png';
const keyboardNum7Down = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum7Down.png';
const keyboardNum8 = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum8.png';
const keyboardNum8Down = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum8Down.png';
const keyboardNum9 = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum9.png';
const keyboardNum9Down = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardNum9Down.png';
const keyboardDot = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardDot.png';
const keyboardDotDown = 'https://computed-cdn.langtaoshuzi.com/template/default/keyboardDotDown.png';

enum MessageEnum {
    UPDATE = 'UPDATE',
}

export interface MessageRes {
    headerBg: string;
    bgImage: string;
    headerBtnBg: string;
    leftIconBtn: string;
    rightCloudBtn: string;
    rightSetBtn: string;
    keyboardFooterBg: string;
    keyboardBg: string;
    keyboardHeaderBg: string;
    boardNormalC: string;
    boardNormalCDown: string;
    keyboardNum0: string;
    keyboardNum0Down: string;
    keyboardNumEq: string;
    keyboardNumEqDown: string;
    keyboardNumChu: string;
    keyboardNumChuDown: string;
    keyboardCheng: string;
    keyboardChengDown: string;
    keyboardTui: string;
    keyboardTuiDown: string;
    keyboardNum1: string;
    keyboardNum1Down: string;
    keyboardNum2: string;
    keyboardNum2Down: string;
    keyboardNum3: string;
    keyboardNum3Down: string;
    keyboardSub: string;
    keyboardSubDown: string;
    keyboardNum4: string;
    keyboardNum4Down: string;
    keyboardNum5: string;
    keyboardNum5Down: string;
    keyboardNum6: string;
    keyboardNum6Down: string;
    keyboardAdd: string;
    keyboardAddDown: string;
    keyboardNum7: string;
    keyboardNum7Down: string;
    keyboardNum8: string;
    keyboardNum8Down: string;
    keyboardNum9: string;
    keyboardNum9Down: string;
    keyboardDot: string;
    keyboardDotDown: string;
}

export interface MessageModel {
    id: number;
    name: string;
    twoColor: string;
    themeColor: string;
    tenantId: number;
    createAt: string;
    updateAt: string;
    status: 0 | 1;
    templateId: string;
    resource: MessageRes,
}

interface Message {
    type: MessageEnum.UPDATE,
    payload: MessageModel,
}

/**
 * 消息发送
 */
export const usePreviewManger = (iframeRef?: Ref<HTMLIFrameElement>) => {
    const onLoaded = ref(false);
    const message = ref<MessageModel>({
        id: 0,
        status: 0,
        templateId: 'default',
        name: '默认模版',
        twoColor: '#FF2A5A',
        themeColor: "#FF2A5A",
        tenantId: 0,
        createAt: "",
        updateAt: "",
        resource: {
            keyboardHeaderBg,
            keyboardBg,
            headerBg,
            bgImage,
            headerBtnBg,
            leftIconBtn,
            rightCloudBtn,
            rightSetBtn,
            keyboardFooterBg,
            boardNormalC,
            boardNormalCDown,
            keyboardNum0,
            keyboardNum0Down,
            keyboardNumEq,
            keyboardNumEqDown,
            keyboardNumChu,
            keyboardNumChuDown,
            keyboardCheng,
            keyboardChengDown,
            keyboardTui,
            keyboardTuiDown,
            keyboardNum1,
            keyboardNum1Down,
            keyboardNum2,
            keyboardNum2Down,
            keyboardNum3,
            keyboardNum3Down,
            keyboardSub,
            keyboardSubDown,
            keyboardNum4,
            keyboardNum4Down,
            keyboardNum5,
            keyboardNum5Down,
            keyboardNum6,
            keyboardNum6Down,
            keyboardAdd,
            keyboardAddDown,
            keyboardNum7,
            keyboardNum7Down,
            keyboardNum8,
            keyboardNum8Down,
            keyboardNum9,
            keyboardNum9Down,
            keyboardDot,
            keyboardDotDown,
        },
    });

    if (iframeRef) {
        // 发送消息
        const postMessage = (message: Message) => {
            try {
                unref(iframeRef).contentWindow.postMessage({
                    type: message.type,
                    payload: JSON.stringify(message.payload),
                });
            } catch (e) {
                console.log(e);
            }
        };
    
        watch(
            () => unref(message),
            (message) => {
                postMessage({
                    type: MessageEnum.UPDATE,
                    payload: message,
                });
            },
            { deep: true },
        );
        
        onMounted(() => {
            nextTick(() => {
                (unref(iframeRef).contentWindow as any).setLoaded = () => {
                    postMessage({
                        type: MessageEnum.UPDATE,
                        payload: unref(message),
                    });
                };
            });
        });
    } else {
        const handleMessage = (event: MessageEvent<Message>) => {
            if (event.data.type === MessageEnum.UPDATE) {
                message.value = JSON.parse(String(event.data.payload)) as MessageModel;
                onLoaded.value = true;
            }
        };
        window.addEventListener('message', handleMessage);
        onMounted(() => {
            if (!(window as any).setLoaded) {
                (window as any).setLoaded = () => {
                    onLoaded.value = true;
                };
            }
            (window as any).setLoaded();
        });
        onUnmounted(() => {
            window.removeEventListener('message', handleMessage);
        });
    }
    return {
        onLoaded,
        message,
    };
};