import { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import Http from "./Http";
import cookie, { CookitMangerEnum } from "./cookie";

interface ApiResult {
    /** 消息内容 */
    message?: string;
    /** 数据 */
    data?: unknown;
    /**
     * 状态码
     * @format int32
     */
    code?: number;
}
  
const responseInterceptors = (value: AxiosResponse<ApiResult, any>) => {
    if (value.data.code !== 200) {
        throw Error(value.data.message);
    }
    return value.data as any;
};

const requestInterceptors = (config: InternalAxiosRequestConfig<any>) => {
    config.headers.tenant_id = cookie.get(CookitMangerEnum.SET_TENANT_ID);
    config.headers.Authorization = cookie.get(CookitMangerEnum.SET_USER_TOKEN);
    return config;
};

export const webApi = new Http({
    baseURL: '/',
    requestInterceptors: [requestInterceptors],
    responseInterceptors: [responseInterceptors],
});