import { getAdTenantList, getAdUserInfo } from '@/service/api';
import { GetPromiseResult } from '@/types/util';
import { defineStore } from 'pinia';
import { ref } from 'vue';

type UserInfo = GetPromiseResult<typeof getAdUserInfo>;
type TenantInfo = GetPromiseResult<typeof getAdTenantList>;

export const useUserStore = defineStore('user', () => {
    const userInfo = ref<UserInfo>({
        id: 0,
        username: '',
        password: '',
        tenantId: 0
    });

    const tenantList = ref<TenantInfo>();
    
    const loadUserInfo = async (isReload = false) => {
        try {
            if (!isReload && userInfo.value?.id) return;
            const [user, tenants] = await Promise.all([
                getAdUserInfo(),
                getAdTenantList(),
            ]);
            userInfo.value = user;
            tenantList.value = tenants;
        } catch (e) {
            console.log(e);
        }
    };

    return {
        userInfo,
        tenantList,
        loadUserInfo,
    };
});
  