import { useMessage } from 'naive-ui';
import { Ref, ref } from 'vue';

export type LoadingHandler = <T>(
    handle: () => Promise<T>,
) => Promise<T | null>;

type UseLoading = () => [Ref<boolean>, LoadingHandler];

export const useLoading: UseLoading = () => {
    const message = useMessage();
    const loading = ref(false);
    const handler: LoadingHandler = async (handle) => {
        try {
            loading.value = true;
            const res = await handle();
            loading.value = false;
            return res;
        } catch (e) {
            loading.value = false;
            message.error((e as Error).message || String(e));
            throw e;
        }
        return null;
    };

    return [loading, handler];
};
