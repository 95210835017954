import "core-js/modules/es.array.push.js";
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, isRef as _isRef, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "table"
};
const _hoisted_2 = {
  key: 0,
  class: "form-box"
};
const _hoisted_3 = {
  class: "action-box"
};
const _hoisted_4 = {
  class: "table-box"
};
import { computed, unref, onMounted, ref } from 'vue';
import { useLoading } from '@/hooks/useLoading';
import { InlineForm, VForm } from '../form';
import { useForm } from '../form/useForm';
export default /*@__PURE__*/_defineComponent({
  __name: 'Table',
  props: {
    api: {
      type: [Function, null]
    },
    add: {
      type: [Function, null],
      default: null
    },
    edit: {
      type: [Function, null],
      default: null
    },
    remove: {
      type: [Function, null],
      default: null
    },
    search: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    },
    pagination: {
      type: [Object, Boolean],
      default: () => ({})
    },
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:data'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const showSearch = computed(() => {
      return !!unref(props.add) || !!unref(props.columns).find(col => col.search);
    });
    const action = ref(false);
    const isAdd = ref(true);
    const [loading, handler] = useLoading();
    const remove = model => __awaiter(this, void 0, void 0, function* () {
      yield handler(() => __awaiter(this, void 0, void 0, function* () {
        if (!unref(props.remove)) return;
        yield unref(props.remove)(model);
      }));
      // eslint-disable-next-line no-use-before-define
      yield onLoadData();
    });
    const edit = model => __awaiter(this, void 0, void 0, function* () {
      // eslint-disable-next-line no-use-before-define
      editModel.value = JSON.parse(JSON.stringify(model));
      isAdd.value = false;
      action.value = true;
    });
    const cols = computed(() => unref(props.columns).map(col => {
      return {
        title: col.title,
        key: col.field,
        width: col.width,
        render: col.render ? record => col.render(record, {
          handler,
          remove,
          edit
        }) : null
      };
    }));
    const emit = __emit;
    const handle = model => __awaiter(this, void 0, void 0, function* () {
      if (!unref(props.api)) return;
      const info = yield unref(props.api)(Object.assign(Object.assign({}, props.pagination), unref(model)));
      emit('update:data', info);
    });
    const handleAdd = () => __awaiter(this, void 0, void 0, function* () {
      if (!unref(props.add)) return;
      action.value = true;
      isAdd.value = true;
    });
    const buttons = [{
      text: '查询',
      button: 'search',
      reload: true,
      handle
    }];
    if (unref(props.add)) {
      buttons.push({
        text: '新增',
        button: 'add',
        reload: false,
        handle: handleAdd
      });
    }
    const [search, searchModel, {
      trigger
    }] = useForm({
      columns: unref(props).columns.filter(col => !!col.search).map(col => {
        return Object.assign(Object.assign({}, col), {
          form: col.search
        });
      }),
      buttons
    });
    const [editForm, editModel, method] = useForm({
      columns: unref(props).columns.filter(col => !!col.form).map(col => {
        return Object.assign(Object.assign({}, col), {
          form: col.form
        });
      }),
      buttons: []
    });
    const onLoadData = () => __awaiter(this, void 0, void 0, function* () {
      if (unref(showSearch)) {
        trigger('search');
      }
      yield handler(() => __awaiter(this, void 0, void 0, function* () {
        yield handle(unref(searchModel));
      }));
    });
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
      if (unref(isAdd)) {
        yield handler(() => __awaiter(this, void 0, void 0, function* () {
          if (!unref(props.add)) return;
          yield method.validate();
          yield unref(props.add)(unref(editModel));
        }));
      } else {
        yield handler(() => __awaiter(this, void 0, void 0, function* () {
          if (!unref(props.edit)) return;
          yield method.validate();
          yield unref(props.edit)(unref(editModel));
        }));
      }
      yield onLoadData();
      action.value = false;
    });
    const handleCancel = () => {
      editModel.value = {};
      action.value = false;
      isAdd.value = false;
    };
    onMounted(onLoadData);
    return (_ctx, _cache) => {
      const _component_n_data_table = _resolveComponent("n-data-table");
      const _component_n_button = _resolveComponent("n-button");
      const _component_n_space = _resolveComponent("n-space");
      const _component_n_card = _resolveComponent("n-card");
      const _component_n_modal = _resolveComponent("n-modal");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [showSearch.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_unref(InlineForm), _mergeProps(_unref(search), {
        value: _unref(searchModel),
        "onUpdate:value": _cache[0] || (_cache[0] = $event => _isRef(searchModel) ? searchModel.value = $event : null)
      }), null, 16, ["value"])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "toolbar")]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_n_data_table, {
        loading: _unref(loading),
        striped: "",
        size: "small",
        columns: cols.value,
        data: __props.data,
        pagination: __props.pagination,
        bordered: false
      }, null, 8, ["loading", "columns", "data", "pagination"])]), _createVNode(_component_n_modal, {
        show: action.value,
        "onUpdate:show": _cache[2] || (_cache[2] = $event => action.value = $event)
      }, {
        default: _withCtx(() => [_createVNode(_component_n_card, {
          style: {
            "max-width": "800px"
          },
          title: isAdd.value ? '新增' : '编辑',
          bordered: false,
          size: "huge",
          role: "dialog",
          "aria-modal": "true"
        }, {
          footer: _withCtx(() => [_createVNode(_component_n_space, null, {
            default: _withCtx(() => [_createVNode(_component_n_button, {
              type: "primary",
              onClick: handleSubmit,
              loading: _unref(loading)
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("确认")])),
              _: 1
            }, 8, ["loading"]), _createVNode(_component_n_button, {
              onClick: handleCancel,
              loading: _unref(loading)
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("取消")])),
              _: 1
            }, 8, ["loading"])]),
            _: 1
          })]),
          default: _withCtx(() => [_createVNode(_unref(VForm), _mergeProps(_unref(editForm), {
            value: _unref(editModel),
            "onUpdate:value": _cache[1] || (_cache[1] = $event => _isRef(editModel) ? editModel.value = $event : null)
          }), null, 16, ["value"])]),
          _: 1
        }, 8, ["title"])]),
        _: 1
      }, 8, ["show"])]);
    };
  }
});