import { Reactive, reactive, Ref, ref } from 'vue';
import type { TablePage, TablePropSchema } from './type';

type useTable = <T extends object>(props: TablePropSchema<T>) => [
    TablePropSchema<T>,
    Ref<T[]>,
    {
        search: Reactive<T>,
        form: Reactive<T>,
        pagination: Reactive<TablePage>,
    },
];

export const useTable: useTable = <T extends object>(props: TablePropSchema<T>) => {
    const search = reactive({} as T);
    const form = reactive({} as T);
    const data = ref<Array<T>>([]) as Ref<T[]>;
    const pagination = reactive<TablePage>(props.pagination === false ? {} : {
        pageSize: 1,
        pageIndex: 10,
    });
    return [
        Object.freeze(props),
        data,
        {
            pagination,
            search,
            form,
        },
    ];
};
