var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import { unref, computed } from 'vue';
import { useLoading } from '@/hooks/useLoading';
import { SchemaFormType } from './type';
import { componentMap } from './componentMap';
import { useProps } from './useProps';
export default /*@__PURE__*/_defineComponent({
  __name: 'InlineForm',
  props: useProps(),
  emits: ['update:value'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const curValue = computed({
      get() {
        return unref(props.value);
      },
      set(value) {
        emit('update:value', value);
      }
    });
    const [loading, handler] = useLoading();
    const triggerButton = id => __awaiter(this, void 0, void 0, function* () {
      for (const btn of props.buttons) {
        if (btn.button === id) {
          if (btn.reload) {
            return handler(() => {
              return btn.handle(unref(props.value));
            });
          }
          return btn.handle(unref(props.value));
        }
      }
      return null;
    });
    __expose({
      triggerButton
    });
    return (_ctx, _cache) => {
      const _component_n_form_item = _resolveComponent("n-form-item");
      const _component_n_button = _resolveComponent("n-button");
      const _component_n_space = _resolveComponent("n-space");
      const _component_n_form = _resolveComponent("n-form");
      return _openBlock(), _createBlock(_component_n_form, {
        value: curValue.value,
        "onUpdate:value": _cache[0] || (_cache[0] = $event => curValue.value = $event),
        "label-width": 80,
        inline: "",
        size: _ctx.size
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, col => {
          return _openBlock(), _createBlock(_component_n_form_item, _mergeProps({
            key: col.field,
            path: col.field,
            ref_for: true
          }, col.form.itemProps), {
            default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(_unref(componentMap)[col.form.type]), _mergeProps({
              value: _ctx.value[col.field],
              "onUpdate:value": $event => _ctx.value[col.field] = $event,
              placeholder: (col.form.type === _unref(SchemaFormType).INPUT ? '请输入' : '请选择') + col.title,
              ref_for: true
            }, col.form.props), null, 16, ["value", "onUpdate:value", "placeholder"]))]),
            _: 2
          }, 1040, ["path"]);
        }), 128)), _createVNode(_component_n_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_n_space, null, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, button => {
              return _openBlock(), _createBlock(_component_n_button, {
                block: "",
                type: "primary",
                loading: _unref(loading),
                key: button.button,
                onClick: $event => triggerButton(button.button)
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(button.text), 1)]),
                _: 2
              }, 1032, ["loading", "onClick"]);
            }), 128))]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["value", "size"]);
    };
  }
});