import { createTextVNode as _createTextVNode2, createVNode as _createVNode2 } from "vue";
import "core-js/modules/es.array.push.js";
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { Table, useTable } from '@/components/table';
import { getAdTemplateList, postAdTemplateAdd, postAdTemplateEdit, postAdTemplateRemove } from '@/service/api';
import { NButton, NPopconfirm, NSpace } from 'naive-ui';
import { useRouter } from 'vue-router';
export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateView',
  setup(__props) {
    const router = useRouter();
    const [table, data, state] = useTable({
      pagination: false,
      api: getAdTemplateList,
      add: postAdTemplateAdd,
      edit: postAdTemplateEdit,
      remove: postAdTemplateRemove,
      columns: [{
        title: '标题',
        field: 'name'
      }, {
        title: '模版标识',
        field: 'templateId'
      }, {
        title: '主题色',
        field: 'themeColor'
      }, {
        title: '结果色',
        field: 'twoColor'
      }, {
        title: '操作',
        field: 'id',
        width: 120,
        render(model, action) {
          return _createVNode2(NSpace, {
            "size": 'small'
          }, {
            default: () => [_createVNode2(NButton, {
              "size": 'tiny',
              "type": "info",
              "onClick": () => {
                router.push({
                  name: 'template/edit',
                  params: {
                    id: model.id
                  }
                });
              }
            }, {
              default: () => [_createTextVNode2("\u7F16\u8F91")]
            }), _createVNode2(NPopconfirm, {
              "on-positive-click": () => action.remove(model),
              "negative-text": "\u53D6\u6D88",
              "positive-text": "\u786E\u8BA4"
            }, {
              default: () => [_createTextVNode2("\u4F60\u786E\u5B9A\u8981\u5220\u9664\uFF1F")],
              trigger: () => _createVNode2(NButton, {
                "size": 'tiny',
                "type": "error"
              }, {
                default: () => [_createTextVNode2("\u5220\u9664")]
              })
            })]
          });
        }
      }]
    });
    const toAdd = () => {
      router.push({
        name: 'template/add'
      });
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(Table), _mergeProps(_unref(table), {
        search: _unref(state).search,
        "onUpdate:search": _cache[0] || (_cache[0] = $event => _unref(state).search = $event),
        form: _unref(state).form,
        "onUpdate:form": _cache[1] || (_cache[1] = $event => _unref(state).form = $event),
        data: _unref(data),
        "onUpdate:data": _cache[2] || (_cache[2] = $event => _isRef(data) ? data.value = $event : null)
      }), {
        toolbar: _withCtx(() => [_createVNode(_unref(NButton), {
          block: "",
          type: "primary",
          onClick: toAdd
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("新增")])),
          _: 1
        })]),
        _: 1
      }, 16, ["search", "form", "data"]);
    };
  }
});