import { webApi } from '@/utils/api';

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiResultObject {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: object;
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface UserLoginDTO {
  username: string;
  password: string;
}

export interface ApiResultLoginResultVo {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: LoginResultVo;
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

/** 数据 */
export interface LoginResultVo {
  tenantList?: Tenants[];
  token?: string;
}

/** tenants */
export interface Tenants {
  /**
   * 用户id
   * @format int64
   */
  id: number;
  /**
   * App应用名称
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * 创建时间
   * @format date-time
   */
  createAt?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateAt?: string;
}

export interface ApiResultCredentials {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: Credentials;
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

/** 数据 */
export interface Credentials {
  securityToken?: string;
  accessKeySecret?: string;
  accessKeyId?: string;
  expiration?: string;
}

/** sa_template */
export interface SaTemplate {
  /**
   * 用户id
   * @format int64
   */
  id: number;
  /**
   * 模版名称
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /** 模版标识 */
  templateId: string;
  /** 背景色 */
  twoColor: string;
  /** 主题色 */
  themeColor: string;
  /** 所有资源列表字符串 */
  resource: string;
  /**
   * 上下架状态，默认0下架,点击自行上架
   * @format int32
   */
  status: number;
  /**
   * 应用标识，后续所有查询新增都需要带上这个标识
   * @format int64
   */
  tenantId: number;
  /**
   * 创建时间
   * @format date-time
   */
  createAt?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateAt?: string;
}

/** sa_order_template */
export interface SaOrderTemplate {
  /**
   * 用户id
   * @format int64
   */
  id?: number;
  /**
   * 档位列表
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * 是否测试档位
   * @format int32
   */
  isSandBox?: number;
  /**
   * 会员有效期(天)
   * @format int64
   */
  day?: number;
  /**
   * 档位金额(分)
   * @format int64
   */
  money?: number;
  /**
   * 登录的租户id
   * @format int64
   */
  tenantId?: number;
  /**
   * 创建时间
   * @format date-time
   */
  createAt?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateAt?: string;
}

/**
 * ad_user
 * 数据
 */
export interface AdUser {
  /**
   * 用户id
   * @format int64
   */
  id: number;
  /**
   * 用户账号
   * @minLength 0
   * @maxLength 255
   */
  username: string;
  /**
   * 用户密码
   * @minLength 0
   * @maxLength 255
   */
  password: string;
  /**
   * 登录的租户id
   * @format int64
   */
  tenantId: number;
  /**
   * 创建时间
   * @format date-time
   */
  createAt?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateAt?: string;
}

export interface ApiResultAdUser {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: AdUser;
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface ApiResultListTenants {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: Tenants[];
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface ApiResultListSaTemplate {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: SaTemplate[];
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface ApiResultSaTemplate {
  /** 消息内容 */
  message?: string;
  data?: SaTemplate;
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

export interface ApiResultListSaOrderTemplate {
  /** 消息内容 */
  message?: string;
  /** 数据 */
  data?: SaOrderTemplate[];
  /**
   * 状态码
   * @format int32
   */
  code?: number;
}

type GetApiResult<T extends { data?: any }> = T extends { data?: infer R } ? R : T;

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl http://localhost:7002
 */

/**
 * No description
 *
 * @tags 用户管理
 * @name Login
 * @summary 用户登录
 * @request POST:/ad/user/login
 */
export const postAdUserLogin = (data: UserLoginDTO) => {
  return webApi.post<GetApiResult<ApiResultLoginResultVo>>(`/ad/user/login`, data);
};

/**
 * No description
 *
 * @tags 资源管理
 * @name Sts
 * @summary 文件上传授权
 * @request POST:/ad/template/sts
 */
export const postAdTemplateSts = () => {
  return webApi.post<GetApiResult<ApiResultCredentials>>(`/ad/template/sts`, {});
};

/**
 * No description
 *
 * @tags 资源管理
 * @name Remove
 * @summary 删除模版
 * @request POST:/ad/template/remove
 */
export const postAdTemplateRemove = (data: SaTemplate) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/template/remove`, data);
};

/**
 * No description
 *
 * @tags 资源管理
 * @name Edit
 * @summary 修改模版
 * @request POST:/ad/template/edit
 */
export const postAdTemplateEdit = (data: SaTemplate) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/template/edit`, data);
};

/**
 * No description
 *
 * @tags 资源管理
 * @name Add
 * @summary 新增模版
 * @request POST:/ad/template/add
 */
export const postAdTemplateAdd = (data: SaTemplate) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/template/add`, data);
};

/**
 * No description
 *
 * @tags 订单档位管理
 * @name Remove1
 * @summary 删除档位
 * @request POST:/ad/orderTemplate/remove
 */
export const postAdOrderTemplateRemove = (data: SaOrderTemplate) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/orderTemplate/remove`, data);
};

/**
 * No description
 *
 * @tags 订单档位管理
 * @name Edit1
 * @summary 修改档位
 * @request POST:/ad/orderTemplate/edit
 */
export const postAdOrderTemplateEdit = (data: SaOrderTemplate) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/orderTemplate/edit`, data);
};

/**
 * No description
 *
 * @tags 订单档位管理
 * @name Add1
 * @summary 新增档位
 * @request POST:/ad/orderTemplate/add
 */
export const postAdOrderTemplateAdd = (data: SaOrderTemplate) => {
  return webApi.post<GetApiResult<ApiResultObject>>(`/ad/orderTemplate/add`, data);
};

/**
 * No description
 *
 * @tags 用户管理
 * @name Info
 * @summary 获取信息
 * @request GET:/ad/user/info
 */
export const getAdUserInfo = () => {
  return webApi.get<GetApiResult<ApiResultAdUser>>(`/ad/user/info`, {});
};

/**
 * No description
 *
 * @tags 应用租户管理
 * @name List
 * @summary 获取全部租户列表
 * @request GET:/ad/tenant/list
 */
export const getAdTenantList = () => {
  return webApi.get<GetApiResult<ApiResultListTenants>>(`/ad/tenant/list`, {});
};

/**
 * No description
 *
 * @tags 资源管理
 * @name List1
 * @summary 获取全部模版
 * @request GET:/ad/template/list
 */
export const getAdTemplateList = () => {
  return webApi.get<GetApiResult<ApiResultListSaTemplate>>(`/ad/template/list`, {});
};

/**
 * No description
 *
 * @tags 资源管理
 * @name GetById
 * @summary 根据指定id获取模版
 * @request GET:/ad/template/get
 */
export const getAdTemplateGet = (query: { id: string }) => {
  return webApi.get<GetApiResult<ApiResultSaTemplate>>(`/ad/template/get`, query);
};

/**
 * No description
 *
 * @tags 订单档位管理
 * @name List2
 * @summary 获取全部档位
 * @request GET:/ad/orderTemplate/list
 */
export const getAdOrderTemplateList = () => {
  return webApi.get<GetApiResult<ApiResultListSaOrderTemplate>>(`/ad/orderTemplate/list`, {});
};
