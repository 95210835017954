import { defineComponent as _defineComponent } from 'vue';
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { onBeforeMount, onUnmounted, ref, unref } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'BoardButtonView',
  props: {
    record: {
      type: Object,
      default: () => ({})
    },
    keyDown: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {}
    }
  },
  setup(__props) {
    const isDown = ref(false);
    const props = __props;
    const handleUp = () => {
      isDown.value = false;
    };
    const handleDown = () => {
      isDown.value = true;
      unref(props.keyDown)(unref(props.record.value));
    };
    onBeforeMount(() => {
      window.addEventListener('mouseup', handleUp);
      window.addEventListener('touchend', handleUp);
    });
    onUnmounted(() => {
      window.removeEventListener('mouseup', handleUp);
      window.removeEventListener('touchend', handleUp);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "BoardButton",
        onMousedownPassive: handleDown,
        onTouchstartPassive: handleDown,
        style: _normalizeStyle({
          backgroundImage: `url(${isDown.value ? __props.record.downUrl : __props.record.normalUrl})`,
          width: `${__props.record.width * __props.record.size[0] + (__props.record.size[0] - 1) * __props.record.space[0]}px`,
          height: `${__props.record.height * __props.record.size[1] + (__props.record.size[1] - 1) * __props.record.space[1]}px`,
          left: `${__props.record.width * __props.record.point[0] + __props.record.point[0] * __props.record.space[0]}px`,
          top: `${__props.record.height * __props.record.point[1] + __props.record.point[1] * __props.record.space[1]}px`
        })
      }, null, 36);
    };
  }
});