import { defineComponent as _defineComponent, createTextVNode as _createTextVNode, createVNode as _createVNode } from 'vue';
import { unref as _unref, isRef as _isRef, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { SchemaFormType } from '@/components/form/type';
import { Table, useTable } from '@/components/table';
import { getAdOrderTemplateList, postAdOrderTemplateAdd, postAdOrderTemplateEdit, postAdOrderTemplateRemove } from '@/service/api';
import { NButton, NPopconfirm, NSpace } from 'naive-ui';
export default /*@__PURE__*/_defineComponent({
  __name: 'OrderTemplateView',
  setup(__props) {
    const [table, data] = useTable({
      api: getAdOrderTemplateList,
      add: postAdOrderTemplateAdd,
      edit: postAdOrderTemplateEdit,
      remove: postAdOrderTemplateRemove,
      columns: [{
        field: 'id',
        title: 'id'
      }, {
        field: 'name',
        title: '充值模版名称',
        search: {
          type: SchemaFormType.INPUT
        },
        form: {
          type: SchemaFormType.INPUT
        }
      }, {
        field: 'isSandBox',
        title: '是否测试模版',
        form: {
          type: SchemaFormType.SELECT,
          props: {
            api: () => Promise.resolve([{
              label: '否',
              value: 0
            }, {
              label: '是',
              value: 1
            }])
          },
          itemProps: {
            rule: [{
              required: true,
              message: '请输入电话号码'
            }]
          }
        }
      }, {
        field: 'day',
        title: '增加会员天数',
        form: {
          type: SchemaFormType.INPUT
        }
      }, {
        field: 'money',
        title: '档位金额(分)',
        form: {
          type: SchemaFormType.INPUT
        }
      }, {
        field: 'action',
        title: '操作',
        width: 100,
        render(model, action) {
          return _createVNode(NSpace, {
            "size": 'small'
          }, {
            default: () => [_createVNode(NButton, {
              "size": 'tiny',
              "type": "info",
              "onClick": () => action.edit(model)
            }, {
              default: () => [_createTextVNode("\u7F16\u8F91")]
            }), _createVNode(NPopconfirm, {
              "on-positive-click": () => action.remove(model),
              "negative-text": "\u53D6\u6D88",
              "positive-text": "\u786E\u8BA4"
            }, {
              default: () => [_createTextVNode("\u4F60\u786E\u5B9A\u8981\u5220\u9664\uFF1F")],
              trigger: () => _createVNode(NButton, {
                "size": 'tiny',
                "type": "error"
              }, {
                default: () => [_createTextVNode("\u5220\u9664")]
              })
            })]
          });
        }
      }]
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(Table), _mergeProps(_unref(table), {
        data: _unref(data),
        "onUpdate:data": _cache[0] || (_cache[0] = $event => _isRef(data) ? data.value = $event : null)
      }), null, 16, ["data"]);
    };
  }
});