import { postAdTemplateSts } from "@/service/api";
import { GetPromiseResult } from "@/types/util";
import { CookitMangerEnum } from "@/utils/cookie";
import { defineStore } from "pinia";
import { ref, unref } from "vue";
import OSS from 'ali-oss';
import dayjs from 'dayjs';

export const useStsStore = defineStore('sts', () => {
    const info = ref<GetPromiseResult<typeof postAdTemplateSts>>(JSON.parse(localStorage.getItem(CookitMangerEnum.SET_STS_INFO) || 'null'));
    
    const getStsInfo = async () => {
        // 保留10秒避免上传过程中过期
        if (!info.value || Date.now() >= (dayjs(unref(info).expiration).valueOf() - 10000)) {
            info.value = await postAdTemplateSts();
            localStorage.setItem(CookitMangerEnum.SET_STS_INFO, JSON.stringify(info.value));
        }
        return unref(info);
    };

    const getOssClient = async () => {
        const res = await getStsInfo();
        const client = new OSS({
            region: 'oss-cn-shanghai',
            bucket: 'computed',
            accessKeyId: res.accessKeyId,
            accessKeySecret: res.accessKeySecret,
            stsToken: res.securityToken,
            refreshSTSToken: async () => {
              await getStsInfo();
              return {
                accessKeyId: info.value.accessKeyId,
                accessKeySecret: info.value.accessKeySecret,
                stsToken: info.value.securityToken,
              };
            },
        });
        return client;
    };

    return {
        getOssClient
    };
});