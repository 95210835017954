

const DPI = 2;

export interface BoardButton {
    normalUrl: string;
    downUrl: string;
    value: string;
    size: [1 | 2, 1 | 2];
    width: number;
    height: number;
    space: [number, number];
    point: [number, number],
}

export const DEVICE_ENUM = {
    IPHONE_X: {
        name: 'iphoneX',
        width: 375 * DPI,
        height: 667 * DPI,
        barHeight: 22 * DPI,
    },
    IPHONE_XR: {
        width: 414 * DPI,
        height: 896 * DPI,
        name: 'iphoneXR',
        barHeight: 22 * DPI,
    },
    IPHONE_12_MINI: {
        width: 375 * DPI,
        height: 812 * DPI,
        name: 'iphone12/13/14 mini',
        barHeight: 22 * DPI,
    },
    IPHONE_12_NORMAL: {
        width: 390 * DPI,
        height: 822 * DPI,
        name: 'iphone12/pro',
        barHeight: 22 * DPI,
    },
    IPHONE_14_15_NORMAL: {
        width: 393 * DPI,
        height: 852 * DPI,
        name: 'iphone14_15 pro',
        barHeight: 22 * DPI,
    },
    IPHONE_14_15_MAX: {
        width: 430 * DPI,
        height: 932 * DPI,
        name: 'iphone14_15 max',
        barHeight: 22 * DPI,
    },
    IPAD_PRO_12_9_4: {
        name: 'iPad Pro 12.9（第4代）',
        barHeight: 22 * DPI,
        width: 1024 * DPI,
        height: 1366 * DPI,
    },
    IPAD_AIR2: {
        name: 'iPad Air 2 (Air 2, Air)',
        barHeight: 22 * DPI,
        width: 768 * DPI,
        height: 1024 * DPI,
    },
    IPAD_11_2: {
        name: 'iPad Pro 11（第2代）',
        barHeight: 22 * DPI,
        width: 834 * DPI,
        height: 1194 * DPI,
    },
    MI_PAD_2: {
        name: '小米平板 2',
        barHeight: 22 * DPI,
        width: 768 * DPI,
        height: 1024 * DPI,
    },
} as const;