var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, isRef as _isRef, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "TemplateActionViewBox"
};
const _hoisted_2 = {
  class: "toolbar"
};
const _hoisted_3 = {
  class: "TemplateActionView"
};
const _hoisted_4 = {
  class: "action"
};
const _hoisted_5 = {
  class: "view"
};
const _hoisted_6 = {
  class: "devices"
};
const _hoisted_7 = ["src"];
import { DEVICE_ENUM } from '@/types/enum';
import { useMessage } from 'naive-ui';
import { computed, onBeforeMount, ref, unref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useElementSize } from '@vueuse/core';
import { usePreviewManger } from '@/hooks/usePreviewManger';
import ResourceUploader from '@/components/ResourceUploader.vue';
import { useLoading } from '@/hooks/useLoading';
import { getAdTemplateGet, postAdTemplateAdd, postAdTemplateEdit } from '@/service/api';
export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateActionView',
  setup(__props) {
    const router = useRouter();
    const msg = useMessage();
    const [loading, handler] = useLoading();
    // 得到对象
    const iframeRef = ref();
    const {
      message
    } = usePreviewManger(iframeRef);
    const sizeWrapperRef = ref();
    const elSize = useElementSize(sizeWrapperRef);
    const device = ref(DEVICE_ENUM.IPHONE_12_MINI.name);
    // 选项
    const options = Object.keys(DEVICE_ENUM).map(key => {
      return {
        label: DEVICE_ENUM[key].name,
        value: DEVICE_ENUM[key].name,
        payload: DEVICE_ENUM[key]
      };
    });
    const route = useRoute();
    const id = computed(() => route.params.id);
    const size = computed(() => {
      return options.find(item => item.value === unref(device)).payload;
    });
    // 尺寸比
    const scale = computed(() => {
      if (!unref(elSize.width)) return 0.3;
      const boxWidth = unref(elSize.width) - 40;
      const boxHeight = unref(elSize.height) - 40;
      return Math.min.apply(null, [boxWidth / unref(size).width, boxHeight / unref(size).height]);
    });
    onBeforeMount(() => __awaiter(this, void 0, void 0, function* () {
      if (route.params.id) {
        const info = yield getAdTemplateGet({
          id: route.params.id
        });
        message.value = Object.assign(Object.assign({}, info), {
          resource: JSON.parse(info.resource)
        });
      }
    }));
    const handleSave = () => __awaiter(this, void 0, void 0, function* () {
      yield handler(() => __awaiter(this, void 0, void 0, function* () {
        // 编辑
        if (unref(route).params.id) {
          yield postAdTemplateEdit(Object.assign(Object.assign({}, unref(message)), {
            id: unref(route).params.id,
            resource: JSON.stringify(unref(message).resource),
            status: 0
          }));
        } else {
          yield postAdTemplateAdd(Object.assign(Object.assign({}, unref(message)), {
            resource: JSON.stringify(unref(message).resource),
            status: 0
          }));
        }
        msg.success(`${unref(route).params.id ? '编辑' : '新增'}成功`);
        router.back();
      }));
    });
    return (_ctx, _cache) => {
      const _component_n_button = _resolveComponent("n-button");
      const _component_n_input = _resolveComponent("n-input");
      const _component_n_form_item = _resolveComponent("n-form-item");
      const _component_n_color_picker = _resolveComponent("n-color-picker");
      const _component_n_form = _resolveComponent("n-form");
      const _component_n_select = _resolveComponent("n-select");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_n_button, {
        size: "small",
        type: "primary",
        onClick: handleSave,
        loading: _unref(loading)
      }, {
        default: _withCtx(() => _cache[28] || (_cache[28] = [_createTextVNode("保存模版")])),
        _: 1
      }, 8, ["loading"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_n_form, {
        value: _unref(message),
        "onUpdate:value": _cache[26] || (_cache[26] = $event => _isRef(message) ? message.value = $event : null)
      }, {
        default: _withCtx(() => [_createVNode(_component_n_form_item, {
          label: "标题"
        }, {
          default: _withCtx(() => [_createVNode(_component_n_input, {
            value: _unref(message).name,
            "onUpdate:value": _cache[0] || (_cache[0] = $event => _unref(message).name = $event)
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "模版标识"
        }, {
          default: _withCtx(() => [_createVNode(_component_n_input, {
            value: _unref(message).templateId,
            "onUpdate:value": _cache[1] || (_cache[1] = $event => _unref(message).templateId = $event)
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "主题色"
        }, {
          default: _withCtx(() => [_createVNode(_component_n_color_picker, {
            value: _unref(message).themeColor,
            "onUpdate:value": _cache[2] || (_cache[2] = $event => _unref(message).themeColor = $event),
            "show-alpha": false
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "结果展示色"
        }, {
          default: _withCtx(() => [_createVNode(_component_n_color_picker, {
            value: _unref(message).twoColor,
            "onUpdate:value": _cache[3] || (_cache[3] = $event => _unref(message).twoColor = $event),
            "show-alpha": false
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "整体背景"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[4] || (_cache[4] = $event => _unref(message).resource = $event),
            options: [{
              label: '整体背景',
              value: 'bgImage'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "头部背景"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[5] || (_cache[5] = $event => _unref(message).resource = $event),
            options: [{
              label: '背景',
              value: 'headerBg'
            }, {
              label: '按钮背景',
              value: 'headerBtnBg'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "头部按钮"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[6] || (_cache[6] = $event => _unref(message).resource = $event),
            options: [{
              label: '换肤按钮',
              value: 'leftIconBtn'
            }, {
              label: '存档按钮',
              value: 'rightCloudBtn'
            }, {
              label: '设置按钮',
              value: 'rightSetBtn'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "键盘区域"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[7] || (_cache[7] = $event => _unref(message).resource = $event),
            options: [{
              label: '键盘头部',
              value: 'keyboardHeaderBg'
            }, {
              label: '键盘中间',
              value: 'keyboardBg'
            }, {
              label: '键盘底部',
              value: 'keyboardFooterBg'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "清空按钮"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[8] || (_cache[8] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'boardNormalC'
            }, {
              label: '按下图片',
              value: 'boardNormalCDown'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "除法"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[9] || (_cache[9] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardNumChu'
            }, {
              label: '按下图片',
              value: 'keyboardNumChuDown'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "乘法"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[10] || (_cache[10] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardCheng'
            }, {
              label: '按下图片',
              value: 'keyboardChengDown'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "回退"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[11] || (_cache[11] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardTui'
            }, {
              label: '按下图片',
              value: 'keyboardTuiDown'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "减"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[12] || (_cache[12] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardSub'
            }, {
              label: '按下图片',
              value: 'keyboardSubDown'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "加"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[13] || (_cache[13] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardAdd'
            }, {
              label: '按下图片',
              value: 'keyboardAddDown'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "等于"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[14] || (_cache[14] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardNumEq'
            }, {
              label: '按下图片',
              value: 'keyboardNumEqDown'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "数字1"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[15] || (_cache[15] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardNum1'
            }, {
              label: '按下图片',
              value: 'keyboardNum1Down'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "数字2"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[16] || (_cache[16] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardNum2'
            }, {
              label: '按下图片',
              value: 'keyboardNum2Down'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "数字3"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[17] || (_cache[17] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardNum3'
            }, {
              label: '按下图片',
              value: 'keyboardNum3Down'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "数字4"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[18] || (_cache[18] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardNum4'
            }, {
              label: '按下图片',
              value: 'keyboardNum4Down'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "数字5"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[19] || (_cache[19] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardNum5'
            }, {
              label: '按下图片',
              value: 'keyboardNum5Down'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "数字6"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[20] || (_cache[20] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardNum6'
            }, {
              label: '按下图片',
              value: 'keyboardNum6Down'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "数字7"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[21] || (_cache[21] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardNum7'
            }, {
              label: '按下图片',
              value: 'keyboardNum7Down'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "数字8"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[22] || (_cache[22] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardNum8'
            }, {
              label: '按下图片',
              value: 'keyboardNum8Down'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "数字9"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[23] || (_cache[23] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardNum9'
            }, {
              label: '按下图片',
              value: 'keyboardNum9Down'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "数字0"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[24] || (_cache[24] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardNum0'
            }, {
              label: '按下图片',
              value: 'keyboardNum0Down'
            }]
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_n_form_item, {
          label: "小数点"
        }, {
          default: _withCtx(() => [_createVNode(ResourceUploader, {
            value: _unref(message).resource,
            "onUpdate:value": _cache[25] || (_cache[25] = $event => _unref(message).resource = $event),
            options: [{
              label: '初始图片',
              value: 'keyboardDot'
            }, {
              label: '按下图片',
              value: 'keyboardDotDown'
            }]
          }, null, 8, ["value"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["value"])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_n_select, {
        block: "",
        options: _unref(options),
        placeholder: "选择设备型号",
        value: device.value,
        "onUpdate:value": _cache[27] || (_cache[27] = $event => device.value = $event),
        clearable: false
      }, null, 8, ["options", "value"])]), _createElementVNode("div", {
        class: "flex",
        ref_key: "sizeWrapperRef",
        ref: sizeWrapperRef
      }, [_createElementVNode("div", {
        class: "size",
        style: _normalizeStyle({
          width: `${size.value.width}px`,
          height: `${size.value.height}px`,
          transform: `translateX(-50%) translateY(-50%) scale(${scale.value})`
        })
      }, [_createElementVNode("div", {
        class: "statusbar",
        style: _normalizeStyle({
          height: `${size.value.barHeight}px`,
          fontSize: `${size.value.barHeight / 2}px`
        })
      }, _cache[29] || (_cache[29] = [_createElementVNode("div", {
        class: "time"
      }, "9:24", -1), _createElementVNode("div", {
        class: "icons"
      }, [_createElementVNode("div", {
        class: "icon"
      })], -1)]), 4), _createElementVNode("iframe", {
        ref_key: "iframeRef",
        ref: iframeRef,
        title: "预览",
        src: `/preview/:${id.value}`,
        width: "100%",
        height: "100%",
        frameborder: "0"
      }, null, 8, _hoisted_7)], 4)], 512)])])]);
    };
  }
});