import { Size } from "naive-ui/es/form/src/interface";
import { KeysRecursive } from "@/types/util";
import { NFormItem, NInput } from "naive-ui";
import ApiSelect from "../ApiSelect.vue";

export enum SchemaFormType {
    INPUT = 'INPUT',
    SELECT = 'SELECT',
}

export interface BasicColumn<T> {
    title: string;
    field: KeysRecursive<T & {
        action: string;
    }>;
}

export type FormBaseColumn<Type extends SchemaFormType> = {
    type: Type;
    itemProps?: InstanceType<typeof NFormItem>['$props'],
    props?: Type extends SchemaFormType.INPUT ? InstanceType<typeof NInput>['$props']
        : Type extends SchemaFormType.SELECT ? InstanceType<typeof ApiSelect>['$props']
        : never;
};

export type BaseFormType = FormBaseColumn<SchemaFormType.INPUT> | FormBaseColumn<SchemaFormType.SELECT>;

export type BasicFormTableColumn<T> = BasicColumn<T> & {
    form: BaseFormType,
};

export type FormPropSchema<T extends object> = {
    size?: Size;
    columns: Array<BasicFormTableColumn<T>>;
    buttons: Array<{
        text: string;
        button: string;
        reload?: boolean;
        handle(model: T): Promise<unknown>;
    }>,
};