import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_n_modal_provider = _resolveComponent("n-modal-provider");
  const _component_n_message_provider = _resolveComponent("n-message-provider");
  const _component_n_config_provider = _resolveComponent("n-config-provider");
  return _openBlock(), _createBlock(_component_n_config_provider, null, {
    default: _withCtx(() => [_createVNode(_component_n_message_provider, null, {
      default: _withCtx(() => [_createVNode(_component_n_modal_provider, null, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}