import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "content"
};
import NavBar from '@/components/NavBar.vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_Container = _resolveComponent("Container");
      return _openBlock(), _createBlock(_component_Container, {
        class: "home"
      }, {
        default: _withCtx(() => [_createVNode(NavBar), _createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view)])]),
        _: 1
      });
    };
  }
});