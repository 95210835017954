import "core-js/modules/es.array.push.js";
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "lines"
};
const _hoisted_2 = {
  class: "line-scroll"
};
const _hoisted_3 = ["textContent"];
import { computed, ref, unref } from 'vue';
import { useWindowSize } from '@vueuse/core';
import { usePreviewManger } from '@/hooks/usePreviewManger';
import BoardButtonView from '@/components/BoardButtonView.vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {
    const isNumber = value => /^[0-9]$/.test(value);
    const lines = ref(['0+0=0']);
    const input = ref('0');
    const {
      message,
      onLoaded
    } = usePreviewManger();
    const size = useWindowSize();
    const blockSize = computed(() => {
      const spaceX = 12;
      const width = (unref(size.width) - spaceX * 5) / 4;
      const height = unref(width) / 175 * 132;
      const spaceY = unref(width) / 175 * spaceX;
      return {
        width,
        height,
        space: [spaceX, spaceY]
      };
    });
    const buttons = computed(() => {
      const {
        width,
        height,
        space
      } = unref(blockSize);
      return [{
        normalUrl: unref(message).resource.boardNormalC,
        downUrl: unref(message).resource.boardNormalCDown,
        size: [1, 1],
        value: 'c',
        width,
        height,
        space,
        point: [0, 0]
      }, {
        normalUrl: unref(message).resource.keyboardNumChu,
        downUrl: unref(message).resource.keyboardNumChuDown,
        size: [1, 1],
        value: '/',
        width,
        height,
        space,
        point: [1, 0]
      }, {
        normalUrl: unref(message).resource.keyboardCheng,
        downUrl: unref(message).resource.keyboardChengDown,
        size: [1, 1],
        value: '*',
        width,
        height,
        space,
        point: [2, 0]
      }, {
        normalUrl: unref(message).resource.keyboardTui,
        downUrl: unref(message).resource.keyboardTuiDown,
        size: [1, 1],
        value: 'del',
        width,
        height,
        space,
        point: [3, 0]
      }, {
        normalUrl: unref(message).resource.keyboardNum1,
        downUrl: unref(message).resource.keyboardNum1Down,
        size: [1, 1],
        value: '1',
        width,
        height,
        space,
        point: [0, 1]
      }, {
        normalUrl: unref(message).resource.keyboardNum2,
        downUrl: unref(message).resource.keyboardNum2Down,
        size: [1, 1],
        value: '2',
        width,
        height,
        space,
        point: [1, 1]
      }, {
        normalUrl: unref(message).resource.keyboardNum3,
        downUrl: unref(message).resource.keyboardNum3Down,
        size: [1, 1],
        value: '3',
        width,
        height,
        space,
        point: [2, 1]
      }, {
        normalUrl: unref(message).resource.keyboardSub,
        downUrl: unref(message).resource.keyboardSubDown,
        size: [1, 1],
        value: '-',
        width,
        height,
        space,
        point: [3, 1]
      }, {
        normalUrl: unref(message).resource.keyboardNum4,
        downUrl: unref(message).resource.keyboardNum4Down,
        size: [1, 1],
        value: '4',
        width,
        height,
        space,
        point: [0, 2]
      }, {
        normalUrl: unref(message).resource.keyboardNum5,
        downUrl: unref(message).resource.keyboardNum5Down,
        size: [1, 1],
        value: '5',
        width,
        height,
        space,
        point: [1, 2]
      }, {
        normalUrl: unref(message).resource.keyboardNum6,
        downUrl: unref(message).resource.keyboardNum6Down,
        size: [1, 1],
        value: '6',
        width,
        height,
        space,
        point: [2, 2]
      }, {
        normalUrl: unref(message).resource.keyboardAdd,
        downUrl: unref(message).resource.keyboardAddDown,
        size: [1, 1],
        value: '+',
        width,
        height,
        space,
        point: [3, 2]
      }, {
        normalUrl: unref(message).resource.keyboardNum7,
        downUrl: unref(message).resource.keyboardNum7Down,
        size: [1, 1],
        value: '7',
        width,
        height,
        space,
        point: [0, 3]
      }, {
        normalUrl: unref(message).resource.keyboardNum8,
        downUrl: unref(message).resource.keyboardNum8Down,
        size: [1, 1],
        value: '8',
        width,
        height,
        space,
        point: [1, 3]
      }, {
        normalUrl: unref(message).resource.keyboardNum9,
        downUrl: unref(message).resource.keyboardNum9Down,
        size: [1, 1],
        value: '9',
        width,
        height,
        space,
        point: [2, 3]
      }, {
        normalUrl: unref(message).resource.keyboardNumEq,
        downUrl: unref(message).resource.keyboardNumEqDown,
        size: [1, 2],
        value: '=',
        width,
        height,
        space,
        point: [3, 3]
      }, {
        normalUrl: unref(message).resource.keyboardNum0,
        downUrl: unref(message).resource.keyboardNum0Down,
        size: [2, 1],
        value: '0',
        width,
        height,
        space,
        point: [0, 4]
      }, {
        normalUrl: unref(message).resource.keyboardDot,
        downUrl: unref(message).resource.keyboardDotDown,
        size: [1, 1],
        value: '.',
        width,
        height,
        space,
        point: [2, 4]
      }];
    });
    const lastNumberIsFloat = input => {
      for (let i = input.length - 1; i >= 0; i -= 1) {
        if (['/', '*', '+', '-'].includes(input[i])) return false;
        if (input[i] === '.') return true;
      }
      return false;
    };
    const handleKeyDown = value => {
      if (value === '=') {
        // eslint-disable-next-line no-eval
        lines.value.push(`${input.value}=${Math.round(eval(input.value) * 100) / 100}`);
        input.value = '';
        return;
      }
      if (value === 'c') {
        input.value = '0';
        return;
      }
      if (value === 'del') {
        input.value = input.value.substring(0, input.value.length - 1);
        return;
      }
      if (input.value.length === 0 && ['/', '*', '+', '-', '.'].includes(value)) {
        input.value = '0';
      }
      const lastValue = input.value[input.value.length - 1];
      if (['/', '*', '+', '-', '.'].includes(lastValue) && ['/', '*', '+', '-', '.'].includes(value)) {
        input.value = input.value.substring(0, input.value.length - 1);
      }
      // 最后一个数字是否包含小数 包含则不继续执行
      if (value === '.' && lastNumberIsFloat(input.value)) return;
      if (!isNumber(value) && lastValue === value) return;
      if (input.value === '0' && isNumber(value)) {
        input.value = value;
        return;
      }
      input.value += value;
    };
    return (_ctx, _cache) => {
      return _unref(onLoaded) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "preview",
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(message).resource.bgImage})`
        })
      }, [_createElementVNode("div", {
        class: "header",
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(message).resource.headerBg})`
        })
      }, [_createElementVNode("div", {
        class: "left",
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(message).resource.headerBtnBg})`
        })
      }, [_createElementVNode("div", {
        class: "icon",
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(message).resource.leftIconBtn})`
        })
      }, null, 4)], 4), _createElementVNode("div", {
        class: "name",
        style: _normalizeStyle({
          textShadow: `0px 1px 2px ${_unref(message).themeColor}`
        })
      }, _toDisplayString(_unref(message).name), 5), _createElementVNode("div", {
        class: "right",
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(message).resource.headerBtnBg})`
        })
      }, [_createElementVNode("div", {
        class: "icon",
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(message).resource.rightCloudBtn})`
        })
      }, null, 4)], 4), _createElementVNode("div", {
        class: "right",
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(message).resource.headerBtnBg})`
        })
      }, [_createElementVNode("div", {
        class: "icon",
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(message).resource.rightSetBtn})`
        })
      }, null, 4)], 4)], 4), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lines.value, (line, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "text",
          style: _normalizeStyle({
            color: `${_unref(message).twoColor}`
          }),
          key: `${line}_${index}`
        }, _toDisplayString(line), 5);
      }), 128))]), _createElementVNode("div", {
        class: "result",
        style: _normalizeStyle({
          color: _unref(message).themeColor
        }),
        textContent: _toDisplayString(input.value)
      }, null, 12, _hoisted_3)]), _createElementVNode("div", {
        class: "keyboard",
        style: _normalizeStyle({
          height: `${buttons.value[0].height * 5 + buttons.value[0].space[1] * 5 + 152}px`
        })
      }, [_createElementVNode("div", {
        class: "key-header",
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(message).resource.keyboardHeaderBg})`
        })
      }, null, 4), _createElementVNode("div", {
        class: "key-body",
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(message).resource.keyboardBg})`
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(buttons.value, button => {
        return _openBlock(), _createBlock(BoardButtonView, {
          record: button,
          key: button.value,
          "key-down": handleKeyDown
        }, null, 8, ["record"]);
      }), 128)), _createElementVNode("div", {
        class: "key-footer",
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(message).resource.keyboardFooterBg})`
        })
      }, null, 4)], 4)], 4)], 4)) : _createCommentVNode("", true);
    };
  }
});