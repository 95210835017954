var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "ResourceUploader"
};
const _hoisted_2 = {
  class: "pic"
};
const _hoisted_3 = ["src"];
import { useLoading } from '@/hooks/useLoading';
import { useStsStore } from '@/store/sts';
import { useUserStore } from '@/store/user';
import { unref } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'ResourceUploader',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:value'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const store = useStsStore();
    const userStore = useUserStore();
    const [loading, handler] = useLoading();
    const props = __props;
    const fileToBlob = file => {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = e => {
          resolve(new Blob([e.target.result]));
        };
      });
    };
    const beforeUpload = (opt, key) => __awaiter(this, void 0, void 0, function* () {
      return handler(() => __awaiter(this, void 0, void 0, function* () {
        const client = yield store.getOssClient();
        const bolb = yield fileToBlob(opt.file.file);
        const filePath = `template/${userStore.userInfo.id}/${opt.file.file.name}`;
        const res = yield client.put(filePath, bolb);
        if (res.url) {
          emit('update:value', Object.assign(Object.assign({}, unref(props.value)), {
            [key]: `https://computed-cdn.langtaoshuzi.com/${filePath}`
          }));
        }
        return false;
      }));
    });
    return (_ctx, _cache) => {
      const _component_n_upload = _resolveComponent("n-upload");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, option => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: option.label
        }, [_createVNode(_component_n_upload, {
          loading: _unref(loading),
          name: option.value,
          "on-before-upload": opt => beforeUpload(opt, option.value)
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [__props.value[option.value] ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            alt: "",
            src: __props.value[option.value]
          }, null, 8, _hoisted_3)) : _createCommentVNode("", true), _createElementVNode("p", null, _toDisplayString(option.label), 1)])]),
          _: 2
        }, 1032, ["loading", "name", "on-before-upload"])]);
      }), 128))]);
    };
  }
});