var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useLoading } from '@/hooks/useLoading';
import { onMounted, ref, unref } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'ApiSelect',
  props: {
    api: {
      type: Function,
      default: () => []
    }
  },
  setup(__props) {
    const options = ref();
    const [loading, handler] = useLoading();
    const props = __props;
    onMounted(() => {
      handler(() => __awaiter(this, void 0, void 0, function* () {
        options.value = yield unref(props.api)();
      }));
    });
    return (_ctx, _cache) => {
      const _component_n_select = _resolveComponent("n-select");
      return _openBlock(), _createBlock(_component_n_select, {
        loading: _unref(loading),
        options: options.value
      }, null, 8, ["loading", "options"]);
    };
  }
});