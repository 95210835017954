import { createRouter, createWebHistory, RouteRecordRaw, RouterView } from 'vue-router';
import HomeIndex from '@/views/home/index.vue';
import LoginIndex from '@/views/login/index.vue';
import PreviewIndex from '@/views/preview/index.vue';
import DashboardView from '@/views/home/children/DashboardView.vue';
import TemplateView from '@/views/home/children/TemplateView.vue';
import TemplateActionView from '@/views/home/children/TemplateActionView.vue';
import OrderTemplateView from '@/views/home/children/OrderTemplateView.vue';

type RouteRecordRawMeta = RouteRecordRaw & {
  meta: {
    title: string;
    hidden?: boolean;
    tenantId?: string;
  };
  children?: Array<RouteRecordRawMeta>;
};

export const children: Array<RouteRecordRawMeta> = [
  {
    path: 'dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      title: '概况',
    },
  },
  {
    path: 'template',
    name: 'template',
    component: RouterView,
    redirect: '/template/record',
    meta: {
      title: '模版管理',
      tenantId: '584589547315270',
    },
    children: [
      {
        path: 'record',
        name: 'template/record',
        component: TemplateView,
        meta: {
          title: '模版管理',
          tenantId: '584589547315270',
        },
      },
      {
        path: 'add',
        name: 'template/add',
        component: TemplateActionView,
        meta: {
          title: '模版新增',
          tenantId: '584589547315270',
        },
      },
      {
        path: 'edit/:id',
        name: 'template/edit',
        component: TemplateActionView,
        meta: {
          title: '模版修改',
          tenantId: '584589547315270',
        },
      },
    ],
  },
  {
    path: 'templateOrder',
    name: 'templateOrder',
    component: OrderTemplateView,
    meta: {
      title: '充值模版管理',
      tenantId: '584589547315270',
    },
    children: [],
  },
];

export const routes: Array<RouteRecordRawMeta> = [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard',
    component: HomeIndex,
    meta: {
      title: '首页',
      tenantId: '584589547315270',
    },
    children,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginIndex,
    meta: {
      title: '登录',
      hidden: true,
    },
  },
  {
    path: '/preview/:id',
    name: 'preview',
    component: PreviewIndex,
    meta: {
      title: '模版预览',
      tenantId: '584589547315270',
    },
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

export default router;
